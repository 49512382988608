<template>
  <div style="padding: 30px 0px; text-align: center">
    {{ tipMessage }}
  </div>
</template>
<script>
import { accessToken } from "../utils/apis/apis.js";
export default {
  name: "wxGetInfo",
  data() {
    return {
      tipMessage: "信息加载中，请稍候...",
      lastLab: "",
      abc: "",
    };
  },
  created() {
    this.abc = window.location.href.split("?")[0];
    this.lastLab = this.abc.slice(this.abc.length - 1, this.abc.length);
    if (!this.$isWeChat) {
      this.tipMessage = "请在微信中打开";
      return;
    }
    let wxCode = this.$route.query.code;
    let state = this.$route.query.state;
    this.$reqGet(
      accessToken.getWeiXinInfo + "?code=" + wxCode + "&state=" + state
    ).then((res) => {
      if (res.data.code === 200) {
        localStorage.userInfo = JSON.stringify(res.data.data);
        setTimeout(() => {
          if (this.lastLab === "0") {
            this.$router.replace("/order-management/0");
          } else if (this.lastLab === "1") {
            this.$router.replace("/order-management/1");
          } else if (this.lastLab === "2") {
            this.$router.replace("/order-management/2");
          }
        });
      }
    });
  },
};
</script>